define("discourse/plugins/discourse-news/discourse/raw-templates/list/news-item-title", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "      <a href=\"" + container.escapeExpression(lookupProperty(helpers, "get").call(alias1, "topic.url", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 15
          },
          "end": {
            "line": 4,
            "column": 28
          }
        }
      })) + "\" class=\"title\">" + ((stack1 = lookupProperty(helpers, "get").call(alias1, "topic.title", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 44
          },
          "end": {
            "line": 4,
            "column": 61
          }
        }
      })) != null ? stack1 : "") + "</a>\n";
    },
    "3": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "      " + container.escapeExpression((lookupProperty(helpers, "topic-link") || depth0 && lookupProperty(depth0, "topic-link") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic", {
        "name": "topic-link",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 6
          },
          "end": {
            "line": 6,
            "column": 26
          }
        }
      })) + "\n";
    },
    "5": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "      <span>|</span>\n      <div class=\"news-item-date\">\n        " + container.escapeExpression((lookupProperty(helpers, "raw-date") || depth0 && lookupProperty(depth0, "raw-date") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic.created_at", {
        "name": "raw-date",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 17,
            "column": 8
          },
          "end": {
            "line": 17,
            "column": 37
          }
        }
      })) + "\n      </div>\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<div class=\"news-item-title\">\n  <span class='link-top-line'>\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.rss", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.program(3, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 4
          },
          "end": {
            "line": 7,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "  </span>\n  \n  <div class=\"link-bottom-line\">\n    <div class=\"news-item-author\">\n      " + container.escapeExpression(lookupProperty(helpers, "get").call(alias1, "topic.creator.displayName", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 12,
            "column": 6
          },
          "end": {
            "line": 12,
            "column": 35
          }
        }
      })) + "\n    </div>\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "topic.created_at", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(5, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 14,
            "column": 4
          },
          "end": {
            "line": 19,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "  </div>\n</div>";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/list/news-item-title", template, {
    core: false,
    pluginName: "discourse-news",
    hasModernReplacement: false
  });
  var _default = _exports.default = template;
});